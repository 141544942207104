<template>
  <div class="bg-cyan-200 dark:bg-cyan-800 shadow py-2 border-cyan-100 dark:border-cyan-900 border-b-2">
    <p class="ml-3 truncate font-medium text-gray-900 dark:text-gray-100">
      <span>{{ info.subject }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'MeetingAllDay',
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  }
};
</script>
