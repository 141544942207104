<template>
  <div :class="{'line-through': info.isCancelled }"
       class="text-gray-900 dark:text-gray-100 shadow bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-600">
    <div class="flex py-3">
      <div class="flex-none mx-2 border-r border-gray-200 dark:border-gray-600 pr-3">
        {{ formattedStart }} - {{ formattedEnd }}
        <div class="w-full bg-gray-200 h-2 dark:bg-gray-600 rounded my-1">
          <div class="bg-amber-800 h-2 dark:bg-amber-200 rounded" :style="{'width': timeLeftPercent + '%'}"
               :class="{'animate-pulse': timeLeftPercent > 0 && timeLeftPercent < 99}"></div>
        </div>
        <div class="text-gray-600 dark:text-gray-300 text-sm flex justify-between font-mono">
          <div class="flex-col">{{ duration }}'</div>
          <div v-if="timeLeftPercent > 0 && timeLeftPercent < 99"
               class="flex-col text-gray-500 dark:text-gray-400">
            {{ Math.round(timeLeft / 1000 / 60) }} mins
          </div>
        </div>
        <div class="mt-2 text-sm">
          <div v-if="timeLeft <= (5 * 60 * 1000) && timeLeft >= 0" class="text-red-500 dark:text-red-300">
            Ends in
            <vue-countdown :time="timeLeft" v-slot="{ totalSeconds }" class="font-mono">
              {{ countdown(totalSeconds) }}
            </vue-countdown>
          </div>
          <div v-if="startsIn <= (15 * 60 * 1000) && startsIn >= 0" class="text-amber-500 dark:text-amber-300">
            Starts in
            <vue-countdown :time="startsIn" v-slot="{ totalSeconds}" class="font-mono">
              {{ countdown(totalSeconds) }}
            </vue-countdown>
          </div>
          <div v-if="startsIn < 0 && timeLeft > (5 * 60 * 1000)" class="text-teal-500 dark:text-teal-300">
            Running
          </div>
        </div>
      </div>
      <div class="flex-1 pl-1">
        <div class="line-clamp-2 font-bold text-lg">
          {{ info.subject }}
          <FireIcon class="h-5 font-bold align-top inline ml-1 dark:text-teal-400" v-if="categories.includes('Important')"></FireIcon>
          <ArrowPathRoundedSquareIcon class="h-5 font-bold align-top inline ml-1 dark:text-teal-400" v-if="categories.includes('Recurring')"></ArrowPathRoundedSquareIcon>
          <GlobeEuropeAfricaIcon class="h-5 font-bold align-top inline ml-1 dark:text-teal-400" v-if="categories.includes('External')"></GlobeEuropeAfricaIcon>
        </div>
        <div class="mt-2" v-if="!isSelfMeeting">
          <MeetingAttendees :persons="info.attendees" :host="info.organizer.emailAddress.address"/>
        </div>
      </div>
      <div class="flex-none mr-2">
        <button
            v-if="!!meetingUrl && timeLeft >= 0"
            type="button"
            @click="startVideoCall"
            class="inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
            :class="{
            'bg-blue-500 hover:bg-blue-600': meetingUrl.type==='zoom',
            'bg-purple-500 hover:bg-purple-600': meetingUrl.type==='teams',
            'bg-green-500 hover:bg-green-600': meetingUrl.type==='google'
          }">
          Join
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {format} from 'date-fns';
import {timePadder, ToLocalDate} from '@/components/utils';
import {ArrowPathRoundedSquareIcon, GlobeEuropeAfricaIcon, FireIcon} from '@heroicons/vue/24/solid';
import MeetingAttendees from '@/components/MeetingAttendees.vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import CONFIG from '@/config';

export default {
  name: 'MeetingElement',
  components: {MeetingAttendees, VueCountdown, ArrowPathRoundedSquareIcon, GlobeEuropeAfricaIcon, FireIcon},
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    formattedStart() {
      return format(ToLocalDate(new Date(this.info.start.dateTime)), 'HH:mm');
    },
    formattedEnd() {
      return format(ToLocalDate(new Date(this.info.end.dateTime)), 'HH:mm');
    },
    startsIn() {
      return ToLocalDate(new Date(this.info.start.dateTime)) - Date.now();
    },
    duration() {
      return (new Date(this.info.end.dateTime).getTime() - new Date(this.info.start.dateTime).getTime()) / 1000 / 60;
    },
    timeLeft() {
      return (Date.now() - ToLocalDate(new Date(this.info.end.dateTime)).getTime()) * -1;
    },
    timeLeftPercent() {
      const startTime = ToLocalDate(new Date(this.info.start.dateTime)).getTime();
      const percent = ((Date.now() - startTime) /
          (ToLocalDate(new Date(this.info.end.dateTime)).getTime() - startTime)) * 100;
      return Math.round(Math.max(0, Math.min(100, percent)));
    },
    statusConferenceRoom() {
      const location = this.info.locations[0];
      if (this.isConferenceRoom) {
        const attendee = this.info.attendees.find((a) => a.emailAddress.address === location.uniqueId);
        return attendee.status.response;
      } else {
        return 'external';
      }
    },
    categories() {
      const categories = [];
      CONFIG.categories.forEach((cat) => {
        if (this.info.categories.includes(cat.value)) {
          categories.push(cat.value);
        }
      });
      return categories;
    },
    isExternalMeeting() {
      return this.info.categories.includes('External');
    },
    isSelfMeeting() {
      return this.info.attendees.length <= 1;
    },
    isConferenceRoom() {
      return this.info.locations[0]?.locationType === 'conferenceRoom';
    },
    meetingUrl() {
      const scope = [this.info.location?.displayName, this.info.onlineMeeting?.joinUrl, this.info.body.content];
      for (let i = 0; i < scope.length; i++) {
        const location = scope[i];
        if (location) {
          const zoomMatches = location.match(/https:\/\/[\w-]*\.?zoom.us\/(j|my)\/[\w?=-]+/g);
          if (zoomMatches && zoomMatches.length > 0) {
            return {type: 'zoom', url: zoomMatches[0]};
          }
          const teamMatches = location.match(/teams.microsoft.com/g);
          if (teamMatches && teamMatches.length > 0) {
            return {type: 'teams', url: location};
          }
          const googleMatches = location.match(/(https:\/\/)?meet\.google\.com\/[a-z-]+/g);
          if (googleMatches && googleMatches.length > 0) {
            return {type: 'google', url: googleMatches[0]};
          }
        }
      }
      return null;
    },
  },
  methods: {
    startVideoCall() {
      if (!this.meetingUrl) {
        return;
      }
      const open = window.open(this.meetingUrl.url, '_blank');
      setTimeout(() => {
        open.close();
      }, 3000);
    },
    countdown(seconds) {
      return timePadder(seconds);
    },
  },
};
</script>
