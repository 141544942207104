<template>
  <div class="">
    <div class="flex" v-if="collapsed || persons.length < 10">
      <div v-for="person in hosts" :key="person.id">
        <MeetingAttendee class="mr-1" :person="person" :host="hostUser"></MeetingAttendee>
      </div>
      <div v-for="person in required" :key="person.id">
        <MeetingAttendee class="mr-1" :person="person" :host="hostUser"></MeetingAttendee>
      </div>
      <div v-if="optional.length > 0" class="mx-1">(</div>
      <div v-for="person in optional" :key="person.id">
        <MeetingAttendee class="mr-1" :person="person" :host="hostUser"></MeetingAttendee>
      </div>
      <div v-if="optional.length > 0">)</div>
    </div>
    <div v-else class="">
      {{ persons.length }} members
    </div>
  </div>
</template>

<script>
import MeetingAttendee from '@/components/MeetingAttendee';

export default {
  name: 'MeetingAttendees',
  components: {MeetingAttendee},
  props: {
    persons: {
      type: Array,
      default() {
        return [];
      },
    },
    host: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      collapsed: false
    }
  },
  computed: {
    hostUser() {
      return this.persons.find((person) => person.emailAddress.address.toLowerCase() === this.host.toLowerCase());
    },
    hosts() {
      return this.persons.filter((person) => person.emailAddress.address.toLowerCase() === this.host.toLowerCase());
    },
    required() {
      return this.persons.filter((person) => person.type === 'required' && this.hostUser !== person).sort((a) => {
        return a.status.response === 'accepted' ? -1 : 1;
      });
    },
    optional() {
      return this.persons.filter((person) => person.type === 'optional').sort((a) => {
        return a.status.response === 'accepted' ? -1 : 1;
      });
    },
  },
};
</script>
