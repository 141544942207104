<template>
  <div class="h-full bg-gray-200 dark:bg-gray-800 overflow-hidden">
    <div class="h-full flex flex-row">
      <div class="basis-full overflow-y-auto bg-white dark:bg-gray-700 dark:[color-scheme:dark]">
        <MeetingAgenda v-if="loggedIn" :msal-object="msal"></MeetingAgenda>
        <GuestScreen v-else></GuestScreen>
      </div>
      <div class="basis-1 bg-gray-200 dark:bg-gray-900 border-l-2 border-gray-400 dark:border-gray-500">
        <SideBar :msal-object="msal" />
      </div>
    </div>
  </div>
</template>

<script>
import {PublicClientApplication} from '@azure/msal-browser';
import Configuration from '@/configuration';
import MeetingAgenda from '@/components/MeetingAgenda';
import SideBar from '@/components/SideBar.vue';
import GuestScreen from '@/components/GuestScreen.vue';
import {ToLocalDate} from '@/components/utils';
import {add} from 'date-fns';

export default {
  name: 'App',
  components: {GuestScreen, SideBar, MeetingAgenda},
  data() {
    return {
      msal: undefined,
      accessToken: undefined,
      expiryListener: undefined,
    };
  },
  watch: {
    expiredDate(newValue, oldValue) {
      if (!oldValue) {
        this.loadAccount();
      }
      const newTime = ToLocalDate(new Date(newValue)).getTime();
      const oldTime = ToLocalDate(new Date(oldValue)).getTime();
      if (newTime !== oldTime) {
        this.loadAccount();
      }
    },
  },
  computed: {
    loggedIn() {
      return Object.keys(this.$store.getters.authObject ?? {}).length > 0;
    },
    expiredDate() {
      return this.$store.getters.accessTokenExpiry;
    },
  },
  beforeUnmount() {
    if (this.expiryListener) {
      clearTimeout(this.expiryListener);
      this.expiryListener = undefined;
    }
  },
  async mounted() {
    this.msal = new PublicClientApplication(Configuration.msalConfig);
    await this.msal.initialize();
    this.loadAccount();
  },
  methods: {
    async loadAccount() {
      const loggedAccounts = this.msal.getAllAccounts();
      if (loggedAccounts.length > 0) {
        const authObject = {account: loggedAccounts[0]};
        const auth = await this.requestSilently(authObject.account);
        this.accessToken = auth.accessToken;
        this.$store.commit('saveAccessToken', this.accessToken);
        this.$store.commit('saveAccessTokenExpiry', auth.expiresOn);
        this.$store.commit('saveAuthObject', auth.account);
        this.addExpiryListener();
      }
    },
    addExpiryListener() {
      if (this.expiryListener) {
        clearTimeout(this.expiryListener);
        this.expiryListener = undefined;
      }
      // Expired date - 10sec = next refresh token
      const timeLeft = ToLocalDate(new Date(this.$store.getters.accessTokenExpiry)).getTime() - 10000 - Date.now();
      this.$store.commit('saveRefreshTokenStamp', add(Date.now(), {seconds: timeLeft / 1000}));
      this.expiryListener = setTimeout(async () => {
        console.warn('ExpiryListener triggered');
        this.loadAccount();
      }, timeLeft);
    },
    async requestSilently(account) {
      const accessTokenRequest = {
        scopes: Configuration.tokenRequest.scopes,
        account: account,
      };
      return await this.msal.acquireTokenSilent(accessTokenRequest);
    },
  },
};
</script>

<style>
html, body, #app {
  height: 100%;
  overflow: auto;
}
</style>
