const CONFIG = {
  categories: [
    {value: 'Important', type: 'priority'},
    {value: 'External', type: 'scope'},
    {value: 'Internal', type: 'scope'},
    {value: 'Kristin', type: 'scope'},
    {value: 'One-Time', type: 'schedule'},
    {value: 'Recurring', type: 'schedule'},
    {value: 'Blocked', type: 'blocker'},
    {value: 'Private', type: 'blocker'},
    {value: 'Todo', type: 'blocker'},
    {value: 'Info', type: 'info'},
    {value: 'Optional', type: 'info'}],
};

export default CONFIG;
