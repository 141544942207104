<template>
  <div class="row">
    <div class="text-gray-400">
      Expired at: {{ expiredDate }} &nbsp; / &nbsp; Refresh at: {{ refreshTokenStamp }}<br>
    </div>
  </div>
</template>

<script>
import {ToLocalDate} from '@/components/utils';
import {format} from 'date-fns';

export default {
  name: 'DebugBox',
  props: {
    msalObject: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    expiredDate() {
      return format(ToLocalDate(new Date(this.$store.getters.accessTokenExpiry)).getTime(), 'yyyy-MM-dd HH:mm:ss');
    },
    refreshTokenStamp() {
      return format(new Date(this.$store.getters.refreshTokenStamp), 'Y-MM-dd HH:mm:ss');
    },
  },
};
</script>
