import {createApp} from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import './registerServiceWorker';
import store from './store';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

const application = createApp(App);
application.use(store);
application.use(PerfectScrollbar);
application.mount('#app');
