<template>
  <main class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <h1 class="mt-4 text-3xl font-bold tracking-tight dark:text-gray-100 sm:text-5xl">Please login!</h1>
      <p class="mt-6 text-base leading-7 dark:text-gray-300">
        Please click in the lower right corner the
        <ArrowLeftOnRectangleIcon class="w-5 inline dark:text-gray-100"></ArrowLeftOnRectangleIcon>
        icon.<br>
        The app can only be used in connection with an O365 account.
      </p>
    </div>
  </main>
</template>

<script>
import {ArrowLeftOnRectangleIcon} from '@heroicons/vue/24/solid';

export default {
  name: 'GuestScreen',
  components: {ArrowLeftOnRectangleIcon},
  methods: {
    clickLogin() {
      this.$emit('login');
    }
  }
};
</script>
