<template>
  <div class="overflow-x-hidden">
    <div
      class="bg-gray-200 dark:bg-gray-900 text-gray-900 dark:text-gray-100 py-2 px-3 text-right border-y-2 border-gray-400 dark:border-gray-500"
    >
      <div class="flex">
        <div v-if="countdownNextMeeting > 0">
          <vue-countdown :time="countdownNextMeeting" v-slot="{ totalSeconds }" class="font-mono text-2xl">
            {{ countdown(totalSeconds) }}
          </vue-countdown>
          &#8211; <small>Until next meeting</small>
        </div>
        <TimeClock class="flex-auto" />
      </div>
    </div>
    <MeetingAllDay v-for="event in allDayEvents" :key="event.id" :info="event"></MeetingAllDay>
    <div class="p-2 bg-gray-300 dark:bg-gray-700" v-if="pastEvents.length > 0">
      <ArrowDownIcon
        v-if="!collapsed"
        class="w-5 h-5 dark:text-gray-100 cursor-pointer"
        @click="() => this.collapsed = true"
      />
      <ArrowUpIcon
        v-if="collapsed"
        class="w-5 h-5 dark:text-gray-100 cursor-pointer"
        @click="() => this.collapsed = false"
      />
      <div v-if="collapsed" class="pb-10">
        <MeetingElement v-for="event in pastEvents" :key="event.id" :info="event"></MeetingElement>
      </div>
    </div>
    <DebugBox :msal-object="msalObject" />
    <template v-for="event in agenda" :key="event.id">
      <MeetingElement v-if="event.type === 'meeting'" :info="event.data"></MeetingElement>
      <MeetingBreak v-if="event.type === 'break'" :info="event.data"></MeetingBreak>
    </template>
  </div>
</template>

<script>
import {timePadder, ToLocalDate} from '@/components/utils';
import VueCountdown from '@chenfengyuan/vue-countdown';
import {ArrowDownIcon, ArrowUpIcon} from '@heroicons/vue/24/solid';
import MeetingElement from '@/components/MeetingElement.vue';
import MeetingBreak from '@/components/MeetingBreak.vue';
import MeetingAllDay from '@/components/MeetingAllDay.vue';
import TimeClock from '@/components/Clock.vue';
import DebugBox from '@/components/settings/DebugBox.vue';
import {format} from 'date-fns';

export default {
  name: 'MeetingAgenda',
  components: {
    DebugBox,
    TimeClock,
    MeetingElement,
    MeetingBreak,
    MeetingAllDay,
    ArrowDownIcon,
    ArrowUpIcon,
    VueCountdown,
  },
  props: {
    msalObject: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      countdownNextMeeting: 0,
      collapsed: false,
      updateListener: undefined,
    };
  },
  computed: {
    agenda() {
      const list = [];
      this.upcomingEvents.forEach((event, i) => {
        if (i === 0) {
          this.countdownNextMeeting = ToLocalDate(new Date(event.start.dateTime)).getTime() - Date.now();
        }
        if (this.upcomingEvents[i - 1]) {
          const difference = ToLocalDate(new Date(event.start.dateTime)).getTime() -
            ToLocalDate(new Date(this.upcomingEvents[i - 1].end.dateTime)).getTime();
          if (difference > 0) {
            list.push({type: 'break', data: difference});
          }
        }
        list.push({type: 'meeting', data: event});
      });
      return list;
    },
    pastEvents() {
      return this.rangedEvents.filter(a => Date.now() > ToLocalDate(new Date(a.end.dateTime)).getTime());
    },
    upcomingEvents() {
      return this.rangedEvents.filter(a => {
        return Date.now() < ToLocalDate(new Date(a.start.dateTime)).getTime() ||
          (Date.now() < ToLocalDate(new Date(a.end.dateTime)).getTime() && Date.now() >
            ToLocalDate(new Date(a.start.dateTime)).getTime());
      });
    },
    allDayEvents() {
      const today = format(new Date(), 'yyyy-MM-dd'); // double check if the date is today (timezone issues)
      return this.$store.getters.events.filter(
        a => a.isAllDay && today === format(new Date(a.start.dateTime), 'yyyy-MM-dd'));
    },
    rangedEvents() {
      return this.$store.getters.events.filter(a => !a.isAllDay).sort((a, b) => {
        return new Date(a.start.dateTime) - new Date(b.start.dateTime);
      });
      //     // attendees - array
      //     // [X] body -> content
      //     // categories - array
      //     // [X] end -> dateTime
      //     // [X] id - string
      //     // importance - string
      //     // isCancelled - boolean
      //     // location - array
      //     // onlineMeeting -> joinUrl
      //     // organizer -> emailAddress
      //     // showAs - string
      //     // [X] start -> dateTime
      //     // [X] subject - string
    },
  },
  mounted() {
    this.startInterval();
  },
  beforeUnmount() {
    clearTimeout(this.updateListener);
  },
  methods: {
    startInterval() {
      const now = new Date();
      const delay = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();
      this.getEvents();
      setTimeout(() => {
        this.getEvents();
        this.updateListener = setInterval(this.getEvents, 60000);
      }, delay);
    },
    getEvents() {
      this.$store.dispatch('getEvents');
    },
    countdown(totalSeconds) {
      return timePadder(totalSeconds, true);
    },
  },
};
</script>
