import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import {v4} from 'uuid';
import API from '@/api';
import Configuration from '@/configuration';
import {add, endOfDay, format, startOfDay} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';

const cookieName = 'cookieName';
const storageKey = 'storageKey';

// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookie.get(cookieName) || v4();

// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, {secure: true, expires: 180});

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () => {
      // Get the store from local storage.
      const store = window.localStorage.getItem(storageKey);
      
      if (store) {
        try {
          // Decrypt the store retrieved from local storage
          // using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(store, encryptionToken);
          
          return JSON.parse(bytes.toString(Crypto.enc.Utf8));
        } catch (e) {
          // The store will be reset if decryption fails.
          window.localStorage.removeItem(storageKey);
        }
      }
      
      return null;
    }, setItem: (key, value) => {
      // Encrypt the store using our encryption token stored in cookies.
      const store = Crypto.AES.encrypt(value, encryptionToken).toString();
      
      // Save the encrypted store in local storage.
      return window.localStorage.setItem(storageKey, store);
    }, removeItem: () => window.localStorage.removeItem(storageKey),
  },
});

const store = new Vuex.Store({
  state: {
    accessToken: undefined,
    accessTokenExpiry: undefined,
    refreshTokenStamp: undefined,
    authObject: undefined,
    lastUpdatedEvents: undefined,
    events: [],
  }, mutations: {
    saveAccessToken: (state, token) => {
      state.accessToken = token;
    }, saveAccessTokenExpiry: (state, date) => {
      state.accessTokenExpiry = date;
    }, saveRefreshTokenStamp: (state, date) => {
      state.refreshTokenStamp = date;
    }, saveAuthObject: (state, auth) => {
      state.authObject = auth;
    }, setEvents(state, events) {
      state.events = events;
    }, lastUpdatedEvents(state, timestamp) {
      state.lastUpdatedEvents = timestamp;
    }, logout(state) {
      state.lastUpdatedEvents = undefined;
      state.events = [];
      state.authObject = undefined;
      state.accessToken = undefined;
    },
  }, getters: {
    accessToken(state) {
      return state.accessToken;
    }, accessTokenExpiry(state) {
      return state.accessTokenExpiry;
    }, refreshTokenStamp(state) {
      return state.refreshTokenStamp;
    }, authObject(state) {
      return state.authObject;
    }, events(state) {
      return state.events ?? [];
    }, lastUpdatedEvents(state) {
      return state.lastUpdatedEvents;
    },
  }, actions: {
    getEvents({commit, state}) {
      const formattedStart = zonedTimeToUtc(
          format(add(startOfDay(new Date()), {minutes: 1}),
              'yyyy-MM-dd HH:mm:ss'), 'UTC').
          toISOString();
      const formattedEnd = zonedTimeToUtc(
          format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'), 'UTC').
          toISOString();
      API.call(
          Configuration.graphConfig.baseUrl + '/me/calendarview',
          {
            StartDateTime: formattedStart,
            EndDateTime: formattedEnd,
            $top: 100,
          },
          state.accessToken,
          function(response) {
            commit('setEvents', response);
            commit('lastUpdatedEvents', Date.now());
          },
          function(error) {
            console.log(error);
            if (error.error?.code === 'InvalidAuthenticationToken') {
              commit('logout');
            }
            if (error.error?.code === 'ApplicationThrottled') {
              alert('Too many requests, please try again later.');
            }
          },
      );
    },
  },
  plugins: [vuexLocal.plugin],
});

export default store;
