<template>
  <div class="clock text-3xl font-bold">{{ time }}</div>
</template>

<script>
export default {
  name: 'TimeClock',
  data() {
    return {
      interval: null,
      time: null,
    };
  },
  beforeUnmount() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  mounted() {
    // update the time every second
    this.updateTime();
    this.interval = setInterval(() => {
      this.updateTime();
    }, 1000);
  },
  methods: {
    updateTime() {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }).format();
    },
  },
};
</script>

<style>
.clock {
  font-family: 'Share Tech Mono', monospace;
}
</style>
