<template>
  <div class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 text-sm p-2.5 cursor-pointer"
       @click="click">
    <ArrowRightOnRectangleIcon class="h-5 w-5" v-if="loggedIn" />
    <ArrowLeftOnRectangleIcon class="h-5 w-5" v-else />
  </div>
</template>

<script>
import {ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon} from '@heroicons/vue/24/solid';
import Configuration from '@/configuration';
import {mapGetters} from 'vuex';

export default {
  name: 'AuthButton',
  components: {ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon},
  props: {
    msal: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(['authObject']),
    loggedIn() {
      return Object.keys(this.$store.getters.authObject ?? {}).length > 0;
    },
  },
  methods: {
    click() {
      if (this.loggedIn) {
        this.onLogout();
      } else {
        this.onLogin();
      }
    },
    onLogin() {
      this.msal.loginPopup(Configuration.loginRequest).then((resp) => {
        this.onAuthorized(resp);
      }).catch(() => {
        this.onAuthorized({});
      });
    },
    onLogout() {
      const logoutRequest = {
        account: this.msal.getAccountByUsername(this.$store.getters.authObject.username),
        postLogoutRedirectUri: Configuration.msalConfig.auth.redirectUri,
        mainWindowRedirectUri: Configuration.msalConfig.auth.redirectUri,
      };
      this.msal.logoutPopup(logoutRequest);
      this.onAuthorized({});
      this.$store.commit('logout');
    },
    onAuthorized(authObject) {
      this.$store.commit('saveAccessTokenExpiry', authObject.expiresOn);
      this.$store.commit('saveAccessToken', authObject.accessToken);
      this.$store.commit('saveAuthObject', authObject.account);
    },
  },
};
</script>
