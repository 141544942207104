<template>
  <div class="py-4 px-2 text-gray-900 dark:text-gray-100 shadow bg-gray-100 border-b border-gray-200 dark:border-gray-600 dark:bg-gray-900">
    <FaceSmileIcon class="w-6 h-6 mr-2 float-left text-gray-600 dark:text-gray-200"/> BREAK - <strong>{{ info / 1000 / 60 }}</strong> min
  </div>
</template>

<script>
import {FaceSmileIcon} from '@heroicons/vue/24/solid';
export default {
  name: 'MeetingBreak',
  components: {FaceSmileIcon},
  props: {
    info: {
      type: Number,
      default: 0,
    },
  },
};
</script>
