const Configuration = {
    loginRequest: {
        scopes: ['User.Read', 'Calendars.Read'],
    }, tokenRequest: {
        scopes: ['User.Read', 'Calendars.Read'], forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
    }, graphConfig: {
        baseUrl: 'https://graph.microsoft.com/v1.0'
    }, msalConfig: {
        auth: {
            // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
            clientId: '97189e5c-03a5-4b93-83ed-14eb1c8c2c4c', // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
            authority: 'https://login.microsoftonline.com/646b9fa9-5f6e-46ca-9608-411cf71f3efd', // Full redirect URL, in form of http://localhost:3000
            redirectUri: window.location.origin,
        }, cache: {
            cacheLocation: 'sessionStorage', // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
    },
};

export default Configuration;
