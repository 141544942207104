import {add} from 'date-fns';

export function ToLocalDate(date) {
    return add(date, {minutes: -1 * new Date().getTimezoneOffset()});
}

export function ToUTCDate(date) {
    return add(date, {minutes: new Date().getTimezoneOffset()});
}

export function timePadder(time, forceHours) {
    const hours = Math.trunc(time / 3600);
    const minutes = Math.trunc((time % 3600) / 60);
    const seconds = time % 60;
    let returnValue = '';
    if (forceHours === true) {
        returnValue = '00:';
    }
    if (hours > 0) {
        returnValue = hours.toString().padStart(2, '0') + ':';
    }
    return returnValue + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
}
