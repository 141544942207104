<template>
  <span class="relative inline-block">
    <span
      class="inline-flex h-6 w-7 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-600 border-gray-200 dark:border-gray-500 border cursor-default"
      :class="{
        'border-green-400 dark:border-green-400': status === 'accepted',
        'border-blue-400 dark:border-blue-400': status === 'hosted',
        'border-red-400 dark:border-red-400': status === 'declined',
      }"
    >
      <span class="text-xs leading-none text-gray-800 dark:text-gray-200"
            :title="this.person.emailAddress.name">
        {{ shortName }}
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'MeetingAttendee',
  props: {
    person: {
      type: Object,
      default() {
        return {};
      },
    },
    host: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    shortName() {
      let name = this.person.emailAddress.name;
      name = name.indexOf('|') > -1 ? name.substr(0, name.indexOf('|')) : name;
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
      let initials = [...name.matchAll(rgx)] || [];
      return (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();
    },
    isExternal() {
      return !this.person.emailAddress.address.endsWith('@rooom.com');
    },
    status() {
      if (
        this.host.emailAddress &&
        this.person.emailAddress.address === this.host.emailAddress.address
      ) {
        return 'hosted';
      }
      return this.person.status.response;
    },
  },
};
</script>
