<template>
  <button
    id="theme-toggle"
    type="button"
    class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 text-sm p-2.5"
    @click="toggleTheme"
  >
    <MoonIcon v-if="mode === 'dark'" class="w-5 h-5" />
    <SunIcon v-if="mode === 'light'" class="w-5 h-5" />
  </button>
</template>

<script>
import {MoonIcon, SunIcon} from '@heroicons/vue/24/solid';

export default {
  name: 'ThemeButton',
  components: {MoonIcon, SunIcon},
  data() {
    return {
      mode: 'dark',
    };
  },
  mounted() {
    // Change the icons inside the button based on previous settings
    if (localStorage.getItem('color-theme') === 'dark' ||
      (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      this.mode = 'light';
    } else {
      this.mode = 'dark';
    }
  },
  methods: {
    toggleTheme() {
      if (this.mode === 'dark') {
        this.mode = 'light';
      } else {
        this.mode = 'dark';
      }
      // if set via local storage previously
      if (localStorage.getItem('color-theme')) {
        if (localStorage.getItem('color-theme') === 'light') {
          document.documentElement.classList.add('dark');
          localStorage.setItem('color-theme', 'dark');
        } else {
          document.documentElement.classList.remove('dark');
          localStorage.setItem('color-theme', 'light');
        }
        // if NOT set via local storage previously
      } else {
        if (document.documentElement.classList.contains('dark')) {
          document.documentElement.classList.remove('dark');
          localStorage.setItem('color-theme', 'light');
        } else {
          document.documentElement.classList.add('dark');
          localStorage.setItem('color-theme', 'dark');
        }
      }
    },
  },
};
</script>
