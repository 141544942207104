<template>
  <div>
    <div class="flex flex-col h-screen justify-between">
      <header>

      </header>
      <main class="mb-auto">
      </main>
      <footer>
        <div
          class="border-b border-gray-400 dark:border-gray-700 py-3 text-center"
          v-if="lastUpdatedEvents"
        >
          <span class="text-gray-700 dark:text-gray-400 text-xs"
                :class="{'dark:text-green-500 hover:dark:text-green-400': updated}">
            {{ lastUpdatedEvents }}
          </span>
          <div class="py-1 text-center pl-2.5">
            <ArrowPathIcon
              class="h-5 w-5 cursor-pointer text-gray-700 dark:text-gray-400 hover:text-gray-900 hover:dark:text-gray-200"
              :class="{'dark:text-green-500 hover:dark:text-green-400': updated}"
              @click="updateEvents"
            />
          </div>
        </div>
        <ThemeButton />
        <AuthButton :msal="msalObject" />
      </footer>
    </div>
  </div>
</template>

<script>
import {format} from 'date-fns';
import {mapGetters} from 'vuex';
import {ArrowPathIcon} from '@heroicons/vue/24/solid';
import ThemeButton from '@/components/settings/ThemeButton.vue';
import AuthButton from '@/components/AuthButton.vue';

export default {
  name: 'SideBar',
  components: {AuthButton, ThemeButton, ArrowPathIcon},
  props: {
    msalObject: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      updated: false,
    };
  },
  computed: {
    ...mapGetters(['authObject']),
    lastUpdatedEvents() {
      return this.$store.getters.lastUpdatedEvents ?
        format(new Date(this.$store.getters.lastUpdatedEvents), 'HH:mm') : undefined;
    },
  },
  methods: {
    updateEvents() {
      this.$store.dispatch('getEvents');
      this.updated = true;
      setTimeout(() => {
        this.updated = false;
      }, 2000);
    },
  },
};
</script>
