const API = {
  /**
   * Helper function to call MS Graph API endpoint
   * using the authorization bearer token scheme
   */
  call(endpoint, params, token, onSuccess, onError) {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    
    headers.append('Authorization', bearer);
    
    const options = {
      method: 'GET', headers: headers,
    };
    
    if (Object.keys(params).length > 0) {
      endpoint += '?' + (new URLSearchParams(params)).toString();
    }
    
    fetch(endpoint, options).
        then(response => response.json()).
        then(response => {
          if (response.error) {
            return onError(response);
          }
          return onSuccess(response.value, response);
        }).
        catch(error => onError(error));
  },
};

export default API;
